.Service_Main {
  width: 100%;
  height: 830px;
}
@media (max-width: 640px) {
  .Service_Main {
    width: 100%;
    height: 1410px;
  }
  .Service_Title {
    font-size: 45px;
    line-height: 40px;
    margin-left: -100px;
  }
  .Container_Service {
    flex-wrap: wrap;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 100%;
    margin: 0px 0px 0px -100px;
    place-content: center;
  }
  .Containers {
    width: 130%;
  }
  .Containers img {
    margin-top: 8px;
  }
  .Containers h1 {
    font-size: 20px;
    margin-left: 10px;
  }
  .Containers p {
    font-size: 14px;
  }
  .PT_Service {
    margin-left: 0px;
    padding-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Service_Main {
    width: 100%;
    height: 1160px;
  }
  .Service_Title {
    font-size: 45px;
    line-height: 40px;
    margin-left: -100px;
  }
  .Container_Service {
    flex-wrap: wrap;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 85%;
    margin: 0px 0px 0px -100px;
    place-content: center;
  }
  .Containers {
    width: 127%;
  }
  .Containers img {
    margin-top: 8px;
  }
  .Containers h1 {
    font-size: 20px;
    margin-left: 10px;
  }
  .Containers p {
    font-size: 14px;
  }
  .PT_Service {
    margin-left: 0px;
    padding-top: 10px;
  }
}
