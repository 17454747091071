@media (max-width: 640px) {
  .Specialist_Main {
    margin-top: 300px;
    max-width: 100%;
    height: 270px;
  }
  .Specialist_Container {
    width: 90%;
    max-height: 190px;
    margin-top: -100px;
  }

  .Specialist_Name {
    flex-wrap: wrap;
    gap: 0;
    max-width: 300px;
    line-height: 25px;
    font-size: 28px;
    margin-left: -39px;
    margin-top: -10px;
  }
  .Specialist_Name p {
    font-size: 23px;
  }
  .Specialist_Sub {
    flex-wrap: nowrap;
    font-size: 15.5px;
    margin-left: -39px;
  }

  .Specialist_Button {
    margin-left: -39px;
    margin-top: 15px;
  }
  .Specialist_Img1 {
    display: none;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .Specialist_Main {
    width: 100%;
    min-height: 430px;
    max-height: 430px;
  }
  .Specialist_Container {
    width: 90%;
    min-height: 220px;
    max-height: 220px;
  }
  .Specialist_Img1 {
    margin-bottom: -16px;
    height: 405px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Specialist_Main {
    margin-top: 300px;
    width: 100%;
    height: 350px;
  }
  .Specialist_Container {
    width: 700px;
    min-height: 200px;
    max-height: 200px;
    margin-top: -20px;
    padding-left: 70px;
  }
  .Specialist_Name {
    flex-wrap: wrap;
    gap: 0;
    width: 60%;
    line-height: 25px;
    font-size: 29px;
    margin-left: -39px;
    margin-top: -10px;
  }
  .Specialist_Sub {
    font-size: 15.5px;
    margin-left: -39px;
  }
  .Specialist_Button {
    margin-left: -39px;
  }
  .Specialist_Img1 {
    display: block;
    max-width: 420px;
    scale: 0.788;
    margin-bottom: -50px;
    margin-left: 360px;
  }
  .Break {
    display: block;
  }
}
