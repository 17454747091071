.About_ImgA {
  margin-top: 10px;
  margin-left: -80px;
  max-width: 400px;
  height: 400px;
  padding: 0px;
}

@media (max-width: 640px) {
  .About_MainA {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    flex-wrap: nowrap;
  }
  .Side_AboutA {
    display: none;
  }
  .About_Sub_MainA {
    flex-wrap: wrap;
    justify-content: center;
    margin-right: auto;
    max-width: 100%;
    min-width: 100%;
    padding-left: 25px;
  }
  .About_ImgA {
    margin-top: -20px;
    padding-top: 0px;
    border-radius: 0px;
    min-width: 128%;
    max-width: 100%;
    position: relative;
    margin-right: -30px;
    height: auto;
  }
  .About_ImageA {
    width: 90%;
    height: 90%;
    margin-top: 10px;
    margin-left: 7.8%;
    margin-right: -30px;
  }
  .About_ContentsA {
    max-width: 100%;
    margin-top: 0px;
  }
  .About_TitleA {
    margin-left: 0px;
  }
  .About_SubA {
    font-size: 11px;
    margin-top: 5px;
  }
  .About_PointsA {
    width: 100%;
  }
  .Point-Separator-Cont {
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Side_AboutA,
  .About_ImgA {
    display: none;
  }
  .About_MainA {
    max-width: 94.7%;
    min-height: 540px;
    flex-wrap: nowrap;
  }
  .About_Sub_MainA {
    margin-right: auto;
    max-width: 100%;
    padding-left: auto;
    margin-left: -55px;
  }
  .About_Img_2A {
    display: block;
    min-width: 250px;
    min-height: 250px;
    max-width: 250px;
    max-height: 250px;
    margin-left: 0px;
    background: #ffffff;
    border-radius: 5px;
  }
  .About_ImageA {
    min-width: 250px;
    min-height: 250px;
    max-width: 250px;
    max-height: 250px;
  }
  .About_ContentsA {
    max-width: 100%;
    max-height: 150px;
    margin-top: 45px;
  }
  .About_TitleA {
    margin-left: 0px;
  }
  .About_SubA {
    font-size: 11px;
    margin-top: 5px;
    height: 100px;
  }
  .About_Points_ImgA {
    width: 43px;
  }
  .About_PointsA {
    max-width: 87%;
    margin-top: 80px;
    margin-left: -300px;
    position: absolute;
  }
  .About_Points_TxtA {
    margin-left: -8px;
  }
  .About_PointsA h1 {
    font-size: 17px;
  }
  .About_PointsA p {
    font-size: 11.5px;
  }
}

.Points-Separator {
  border-top: 1px solid #a7f3d0;
  width: 100%;
  margin: 10px auto 0;
}


@media (min-width: 1200px) and (max-width: 1439px) {
  .About_MainA {
    max-width: 80.8%;
  }
}