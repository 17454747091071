@media (max-width: 640px) {
  .WCU_Main {
    width: 100%;
    height: 720px;
  }
  .WCU_Img {
    display: none;
  }
  .WCU_Title {
    margin-top: -10px;
    margin-left: 30px;
  }
  .Title {
    font-size: 32px;
  }
  .WCU_Sub {
    font-size: 14px;
    width: 93%;
  }
  .Container_Main {
    scale: 0.965;
  }
  .Container_WCU {
    flex-wrap: wrap;
    margin-left: -10px;
    margin-bottom: -8px;
  }
  .Container_WCU Button {
    width: 94%;
    justify-content: left;
  }

  .Button1 div {
    margin-left: 15px;
    padding-left: 0px;
  }
  .Button2 div {
    margin-left: -1px;
    padding-left: 0px;
  }
  .Button3 div {
    margin-left: 9px;
    padding-left: 0px;
  }
  .Button4 div {
    margin-left: -13px;
    padding-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .WCU_Main {
    width: 100%;
    height: 440px;
  }
  .WCU_Img {
    display: none;
  }
  .WCU_Title {
    margin-top: -60px;
    margin-left: 0px;
  }
  .Title {
    font-size: 32px;
  }
  .WCU_Sub {
    font-size: 14px;
    width: 93%;
  }
  .WCU {
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .Container_Main {
    scale: 1;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .Container_WCU {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -8px;
    justify-content: center;
    align-items: center;
    max-width: 800px;
  }
  .Container_WCU Button {
    max-width: 100%;
    justify-content: left;
  }

  .Button1 div {
    margin-left: 15px;
    padding-left: 0px;
  }
  .Button2 div {
    margin-left: -1px;
    padding-left: 0px;
  }
  .Button3 div {
    margin-left: 9px;
    padding-left: 0px;
  }
  .Button4 div {
    margin-left: -13px;
    padding-left: 0px;
  }
}
