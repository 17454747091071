/* Loading Animation */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (min-width: 641px) and (max-width: 767px) {
  .Not_Supported_Screen_Alert {
    display: block;
  }
  .App_Main {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1250px) {
  .Not_Supported_Screen_Alert {
    display: block;
  }
  .App_Main {
    display: none;
  }
}
