@media (max-width: 640px) {
  .About_Main1 {
    padding-left: 30px;
    padding-right: 15px;
    min-height: 860px;
    width: 100%;
    min-width: 100%;
  }

  .About_Main_Title {
    margin-top: -20px;
  }

  .Flex_About {
    flex-wrap: wrap;
    width: 100%;
    margin-left: 10px;
    margin-top: 0px;
  }
  .Flex_About_Div {
    margin-left: 0px;
  }
  .About_Title {
    margin-left: 0px;
  }

  .Sub_About {
    font-size: 20px;
    width: 93%;
    margin-left: 0;
  }

  .Sub_About_Span {
    font-size: 14px;
  }
  .Points {
    grid: none;
  }
  .Points span {
    margin: -4px 0px;
  }
  .Point2 {
    margin-left: 0px;
  }
  .Point2_Img {
    width: 36px;
    margin-top: 11px;
  }

  .About_Button {
    margin-top: 30px;
    margin-left: 0px;
  }

  .Heart_Beat {
    display: none;
  }
  .About_Img_Container1 {
    margin-left: -4%;
    padding-left: 0px;
    position: relative;
    margin-top: -110px;
    width: 95%;
  }
  .About_Img_Container1 div {
    display: block;
    scale: 0.8;
    margin-top: 10px;
  }

  .About_Img1 {
    min-width: 110%;
    position: relative;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .About_Main1 {
    padding-left: auto;
    padding-right: auto;
    height: 530px;
    max-width: 100%;
  }

  .Flex_About {
    flex-wrap: nowrap;
    max-width: 1100px;
  }

  .Flex_About_Div {
    margin-left: 0px;
  }
  .Sub_About {
    font-size: 45px;
    max-width: 600px;
    margin-left: 0;
  }

  .Sub_About_Span {
    font-size: 15px;
    width: 750px;
  }

  .About_Button {
    margin-top: 20px;
    margin-left: 0;
  }
  .SL {
    margin-top: -10px;
  }
  .Point2 {
    margin-left: 10px;
  }
  .Point_Txt {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .About_Main1 {
    padding-left: 60px;
    padding-right: 15px;
    min-height: 830px;
  }

  .Flex_About_Div {
    margin-left: -20px;
  }
  .About_Main_Title {
    margin-top: -20px;
  }

  .Flex_About {
    flex-wrap: wrap;
    width: 100%;
    margin-left: 10px;
    margin-top: 0px;
  }

  .About_Title {
    margin-left: 0px;
  }

  .Sub_About {
    font-size: 20px;
    width: 90%;
    margin-left: 0;
  }

  .Sub_About_Span {
    font-size: 14px;
    width: 109%;
  }
  .Points {
    width: 700px;
    margin-top: 30px;
  }
  .Points span {
    margin: -5px 0px;
  }
  .Point2 {
    margin-left: 0px;
  }

  .About_Button {
    margin-top: 30px;
    margin-left: 0px;
  }

  .Heart_Beat {
    display: none;
  }
  .About_Img_Container1 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
    margin-top: -50px;
    min-width: 450px;
    height: 400px;
    scale: 1.1;
  }
  .About_Img_Container1 div {
    display: block;
    margin-left: 340px;
    margin-top: 0px;
  }

  .About_Img1 {
    min-width: 100%;
    position: relative;
    margin-left: -15px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .About_Main1 {
    max-width: 100%;
  }
  .About_Img_Container1 {
    margin-left: -80px;
  }
}
