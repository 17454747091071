@media (max-width: 640px) {
  .Main {
    height: 620px;
    width: 100%;
    padding: 0 0px; /* Added padding to prevent content touching the edges */
  }

  .Main_Img {
    overflow: hidden;
    margin-top: 0px;
    min-height: 740px;
  }
  .Bg_Main {
    min-width: 230%;
    min-height: 740px;
    margin-left: -200px;
  }

  .Main_Name {
    min-width: 150%;
    max-width: 150%;
    font-size: 40px;
    font-weight: bolder;
    line-height: 40px;
    margin-top: 20px;
    margin-left: 0px;
  }

  .Sub_Name {
    min-width: 60%;
    max-width: 60%;
    margin-left: 0px;
    font-size: 10px;
  }

  .Get_Started {
    margin-left: 0px;
  }
  .Main_Over {
    top: 620px;
    margin-left: 30px;
  }
  .Design_Main {
    margin-top: 350px;
    position: absolute;
    right: 270px;
    width: 165px;
    justify-content: space-around;
  }

  .Design_Main img {
    width: 10px;
    height: 10px;
  }

  .Main_Quote {
    font-size: 10px;
    margin-top: 230px;
    margin-left: 30px;
  }

  /* .Main_Quote {
    font-size: 12px;
    margin-top: 70px;
  }

  .Success {
    scale: 0.9;
    margin-left: 30px;
    margin-top: 8px;
    width: 5500px;
    height: 100px;
  } */

  .Success {
    font-size: 12px;
    scale: 0.8;
    margin-left: -560px;
    margin-top: 8px;
    width: 5500px;
    height: 100px;
  }

  .Success_Main {
    gap: 20px;
    width: 100%;
    margin: -10px -20px;
  }

  .Font_Main {
    font-size: 10px;
  }

  .Font_Main b {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Main {
    height: 620px;
    max-width: 100%;
    padding: 0 0px; /* Added padding to prevent content touching the edges */
  }

  .Main_Img {
    max-width: 100%;
    max-height: 730px;
  }
  .Bg_Main {
    min-width: 230%;
    min-height: 620px;
    margin-left: -500px;
  }

  .Main_Name {
    min-width: 150%;
    max-width: 150%;
    font-size: 73px;
    font-weight: bolder;
    line-height: 60px;
    margin-top: 120px;
    margin-left: 60px;
  }

  .Sub_Name {
    min-width: 70%;
    max-width: 70%;
    margin-left: 60px;
    font-size: 12.5px;
  }

  .Get_Started {
    margin-left: 60px;
  }
  .Main_Over {
    top: 620px;
    margin-left: 30px;
  }
  .Design_Main {
    margin-top: 50px;
    position: absolute;
    right: -10%;
    width: 165px;
    justify-content: space-around;
  }

  .Design_Main img {
    width: 10px;
    height: 10px;
  }

  .Main_Quote {
    font-size: 12px;
    margin-top: 70px;
  }

  .Success {
    scale: 0.9;
    margin-left: 30px;
    margin-top: 8px;
    width: 5500px;
    height: 100px;
  }

  .Success_Main {
    gap: 10px;
    width: 100%;
    margin: -10px -20px;
  }

  .Font_Main {
    font-size: 11px;
    min-width: 110px;
  }

  .Font_Main b {
    font-size: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .Success {
    scale: 1;
    margin-left: 550px;
    margin-top: -40px;
    width: 5500px;
    height: 100px;
  }
}
