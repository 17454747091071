/* Navbar.css */

.navbar-toggle,
.navbar-center,
.navbar-book {
  display: none;
}

.navbar-content {
  display: flex;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
}
.Navbar {
  max-width: 100%;
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    height: 60px;
  }

  .QuoteNav {
    margin-top: 40vh;
  }

  .navbar-toggle,
  .navbar-center,
  .navbar-book {
    display: flex;
  }
  .navbar-center {
    margin-left: 14%;
  }
  .navbar-content {
    display: none;
  }

  .Menu_Items_Quote {
    margin-top: 80%;
  }
  .navbar-appointment {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    width: 100%;
    height: 60px;
  }

  .navbar-toggle,
  .navbar-center,
  .navbar-book {
    display: flex;
    margin: auto 4%;
  }
  .navbar-center {
    margin-left: 18.8%;
  }
  .Menu_Links {
    padding-left: 6.5%;
  }
  .navbar-content,
  .navbar-center {
    display: none;
  }
  .navbar-center2 {
    display: block;
    padding-top: 20px;
  }
  .Menu_Items_Quote {
    margin-top: 40%;
  }
  .navbar-appointment {
    display: none;
  }
}
