@media (max-width: 640px) {
  .FindUs_Main {
    width: 100%;
    height: 560px;
    margin-top: 860px;
    padding: 0px;
  }
  .FindUs_Title {
    font-size: 40px;
    margin-left: 5%;
  }
  .FindUs_Main_Grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 90%;
    margin: auto 5%;
    margin-top: 20px;
    place-content: center;
  }
  .FindUs_Grid img {
    width: 35px;
  }
  .FindUs_Numbers h1 {
    font-size: 16px;
  }
  .FindUs_Numbers p {
    font-size: 12px;
  }
  .FindUs_Map {
    width: 90%;
    margin: auto 5%;
  }
  .FindUs_Map iframe {
    height: 250px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .FindUs_Main {
    width: 100%;
    padding: 50px;
  }
  .FindUs_Title {
    margin-bottom: 30px;
  }
  .FindUs_Main_Grid {
    display: flex;
    justify-content: space-between;
    grid-template-columns: none;
  }
  .FindUs_Grid1,
  .FindUs_Grid3 {
    width: 110%;
    height: 50px;
    padding: 0px;
  }
  .FindUs_Grid3 {
    margin-left: -14px;
  }
  .FindUs_Grid2 {
    width: 120%;
    margin-left: -24px;
    height: 50px;
    padding: 0px;
  }
  .FindUs_Grid2 .FindUs_Numbers {
    margin-left: -10px;
  }
  .lazy-load-image {
    width: 35px;
  }
  .FindUs_Numbers {
    margin-left: -10px;
  }
  .FindUs_Numbers h1 {
    margin-top: -3px;
    font-size: 16px;
  }
  .FindUs_Numbers p {
    font-size: 12px;
  }
  .FindUs_Main_Grid {
    width: 100%;
    margin: auto 0%;
  }
  .FindUs_Map {
    min-width: 100%;
    margin: auto 0%;
  }
  .FindUs_Map iframe {
    height: 250px;
    width: 100%;
  }
}
