@media (max-width: 640px) {
  .Form_MainB {
    min-width: 110%;
    height: 855px;
    margin-left: 25px;
    margin-top: -10px;
  }
  .Form_Title {
    font-size: 30px;
    margin-left: -40px;
  }
  .Form_Grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    min-width: 118%;
    margin: auto -40px auto -40px;
  }
  .Form_Button {
    margin-top: 15px;
    margin-left: -40px;
  }
  .Grid_Inputs {
    margin-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Form_MainB {
    margin: 0px;
    padding: 0px;
  }
  .Form_Grid {
    margin-left: 0px;
    min-width: 100%;
  }
  .Form_Title {
    justify-content: center;
    margin-left: 0px;
  }
  .Form_Button {
    margin-left: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .Form_MainB {
    margin-left: -30px;
  }
}
